<template>
  <div v-if="onCloseComputed">
    <div
      class="pwaPromptOverlay iOSPWA-overlay"
      v-bind:class="[visibilityClass, iOSClass]"
      aria-label="Close"
      role="button"
      @click="dismissPrompt()"
    />
    <div
      class="pwaPrompt iOSPWA-container"
      v-bind:class="[visibilityClass, iOSClass]"
      aria-describedby="pwa-prompt-description"
      aria-labelledby="pwa-prompt-title"
      role="dialog"
    >
      <div class="pwaPromptHeader iOSPWA-header">
        <p id="pwa-prompt-title" class="pwaPromptTitle iOSPWA-title">
          {{ copyTitle }}
        </p>
        <button class="pwaPromptCancel iOSPWA-cancel" @click="dismissPrompt()">
          {{ copyClosePrompt }}
        </button>
      </div>
      <div class="pwaPromptBody iOSPWA-body">
        <div class="pwaPromptDescription iOSPWA-description">
          <p
            id="pwa-prompt-description"
            class="pwaPromptCopy iOSPWA-description-copy"
          >
            {{ copyBody }}
          </p>
        </div>
      </div>
      <div class="pwaPromptInstruction iOSPWA-steps">
        <div class="pwaPromptInstructionStep iOSPWA-step1">
          <ShareIcon class="pwaPromptShareIcon iOSPWA-step1-icon" />
          <p class="pwaPromptCopy bold iOSPWA-step1-copy">
            {{ copyShareButtonLabel }}
          </p>
        </div>
        <div class="pwaPromptInstructionStep iOSPWA-step2">
          <HomeScreenIcon class="pwaPromptHomeIcon iOSPWA-step2-icon" />
          <p class="pwaPromptCopy bold iOSPWA-step2-copy">
            {{ copyAddHomeButtonLabel }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeScreenIcon from "./elements/HomeScreenIcon.vue";
import ShareIcon from "./elements/ShareIcon.vue";

const deviceCheck = () => {
  const isiOS = /iphone|ipad|ipod/.test(
    window.navigator.userAgent.toLowerCase()
  );
  const isiPadOS =
    navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1;
  const isStandalone =
    "standalone" in window.navigator && window.navigator.standalone;
  return (isiOS || isiPadOS) && !isStandalone;
};

export default {
  name: "pwaIosPrompt",
  data: function() {
    return {
      timesToShow: 3,
      promptOnVisit: 1,
      permanentlyHideOnDismiss: false,
      copyTitle: "Zet op beginscherm",
      copyBody:
        "Deze website heeft app functionaliteit. Voeg dit toe op het beginscherm om sneller te kunnen raten en fullscreen te gebruiken.",
      copyShareButtonLabel: "1) Klik 'Delen' knop onder de menu balk beneden.",
      copyAddHomeButtonLabel: "2) Klik 'Zet op beginscherm'.",
      copyClosePrompt: "Cancel",
      delay: 2000,
      debug: false,
      promptData: {},
      maxVisits: null,
      isVisible: false,
      ifAction: false,
    };
  },
  components: {
    HomeScreenIcon,
    ShareIcon,
  },
  computed: {
    isIosDevice() {
      return deviceCheck();
    },
    isiOS13AndUp() {
      return /OS (13|14)/.test(window.navigator.userAgent);
    },
    visibilityClass() {
      return this.isVisible ? "visible" : "hidden";
    },
    iOSClass() {
      return this.isiOS13AndUp ? "modern" : "legacy";
    },
    onCloseComputed() {
      return this.onClose();
    },
  },
  methods: {
    tempAction() {
      this.ifAction = true;
    },
    dismissPrompt() {
      document.body.classList.remove("noScroll");
      this.isVisible = false;
      if (this.permanentlyHideOnDismiss) {
        localStorage.setItem(
          "iosPwaPrompt",
          JSON.stringify({
            ...this.promptData,
            visits: this.maxVisits,
          })
        );
      }
      if (typeof this.onClose() === "function") {
        this.onClose();
      }
    },
    onClose() {
      this.promptData = JSON.parse(localStorage.getItem("iosPwaPrompt"));

      if (this.promptData === null) {
        this.promptData = { isiOS: deviceCheck(), visits: 0 };
        localStorage.setItem("iosPwaPrompt", JSON.stringify(this.promptData));
      }

      if (this.promptData.isiOS || this.debug) {
        const aboveMinVisits = this.promptData.visits + 1 >= this.promptOnVisit;
        const belowMaxVisits =
          this.promptData.visits + 1 < this.promptOnVisit + this.timesToShow;

        if (belowMaxVisits || this.debug) {
          localStorage.setItem(
            "iosPwaPrompt",
            JSON.stringify({
              ...this.promptData,
              visits: this.promptData.visits + 1,
            })
          );

          if (aboveMinVisits || this.debug) {
            this.maxVisits = this.timesToShow + this.promptOnVisit;
            this.ifAction = true;
            return true;
          }
        }
      }
      this.ifAction = false;
      return false;
    },
    create() {
      if (this.delay) {
        setTimeout(() => {
          // Prevent keyboard appearing over the prompt if a text input has autofocus set
          if (document.activeElement) {
            document.activeElement.blur();
          }
          this.isVisible = true;
        }, this.delay);
      }
      if (this.isVisible) {
        document.body.classList.add("noScroll");
      }
    },
  },
  watch: {
    ifAction(val, old) {
      console.log("change val");
      if (val == true && val !== old) {
        this.create();
      }
    },
  },
};
</script>

<style>
.noScroll {
  overflow: hidden;
}
.pwaPromptOverlay {
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.2s ease-in;
  width: 100vw;
  z-index: 999999;
}
.pwaPromptOverlay.visible {
  opacity: 1;
  display: block;
}
.pwaPromptOverlay.hidden {
  pointer-events: none;
  touch-action: none;
}
@media (prefers-color-scheme: dark) {
  .pwaPromptOverlay.modern {
    background: rgba(10, 10, 10, 0.5) !important;
    color: rgba(235, 235, 245, 0.6) !important;
  }
}
.pwaPrompt {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(250, 250, 250, 0.8);
  border-radius: 10px;
  bottom: 0;
  color: black;
  filter: brightness(1.1);
  left: 0;
  margin: 0 8px 10px;
  overflow: hidden;
  position: fixed;
  transform: translateY(calc(100% + 10px));
  transition: transform 0.4s cubic-bezier(0.4, 0.24, 0.3, 1);
  width: calc(100vw - 16px);
  z-index: 999999;
}
.pwaPrompt.visible {
  transform: translateY(0);
  display: block;
}
.pwaPrompt.hidden {
  pointer-events: none;
  touch-action: none;
}
.pwaPrompt.modern {
  background: rgba(255, 255, 255, 0.6);
  filter: brightness(1.6);
}
@media (prefers-color-scheme: dark) {
  .pwaPrompt.modern {
    background: rgba(65, 65, 65, 0.7) !important;
    filter: brightness(1.1) !important;
  }
}
.pwaPromptHeader {
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 0.5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 13px 16px;
}
.modern .pwaPromptHeader {
  border-color: rgba(60, 60, 67, 0.29);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader {
    border-color: rgba(140, 140, 140, 0.7) !important;
  }
}
.pwaPromptHeader .pwaPromptTitle {
  color: #333;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.125;
  margin: 0;
  padding: 0;
}
.modern .pwaPromptHeader .pwaPromptTitle {
  color: rgba(0, 0, 0, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader .pwaPromptTitle {
    color: rgba(255, 255, 255, 1) !important;
  }
}
.pwaPromptHeader .pwaPromptCancel {
  color: #2d7cf6;
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
}
.modern .pwaPromptHeader .pwaPromptCancel {
  color: rgba(0, 85, 179, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptHeader .pwaPromptCancel {
    color: rgba(9, 132, 255, 1) !important;
  }
}
.pwaPromptBody {
  display: flex;
  width: 100%;
}
.pwaPromptBody .pwaPromptDescription {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-width: 0.5px;
  color: inherit;
  margin: 0 16px;
  padding: 16px;
  width: 100%;
}
.modern .pwaPromptBody .pwaPromptDescription {
  border-color: rgba(60, 60, 67, 0.29);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptBody .pwaPromptDescription {
    border-color: rgba(140, 140, 140, 0.7) !important;
  }
}
.pwaPromptCopy {
  color: #7b7b7a;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  padding: 0;
}
.pwaPromptCopy.bold {
  font-weight: 600;
}
.modern .pwaPromptCopy {
  color: rgba(60, 60, 67, 0.6);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptCopy {
    border-color: rgba(235, 235, 245, 0.6) !important;
    color: rgba(235, 235, 245, 0.6) !important;
  }
}
.pwaPromptInstruction {
  color: inherit;
  margin: 0 16px;
  padding: 16px;
}
.pwaPromptInstruction .pwaPromptInstructionStep {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 16px;
}
.pwaPromptInstruction .pwaPromptInstructionStep:last-of-type {
  margin-bottom: 0;
}
.pwaPromptInstruction .pwaPromptShareIcon,
.pwaPromptInstruction .pwaPromptHomeIcon {
  flex: 0 0 auto;
  height: 30px;
  margin-right: 32px;
  width: 25px;
}
.pwaPromptInstruction .pwaPromptHomeIcon {
  color: #2d7cf6;
}
.modern .pwaPromptInstruction .pwaPromptHomeIcon {
  color: black;
  fill: black;
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptInstruction .pwaPromptHomeIcon {
    color: white !important;
    fill: white !important;
  }
}
.pwaPromptInstruction .pwaPromptShareIcon {
  color: #2d7cf6;
  fill: #2d7cf6;
}
.modern .pwaPromptInstruction .pwaPromptShareIcon {
  color: rgba(0, 85, 179, 1);
  fill: rgba(0, 85, 179, 1);
}
@media (prefers-color-scheme: dark) {
  .modern .pwaPromptInstruction .pwaPromptShareIcon {
    color: rgba(9, 132, 255, 1) !important;
    fill: rgba(9, 132, 255, 1) !important;
  }
}
</style>
