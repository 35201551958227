const messages = {
  en: {
    home: {
      welcome: `Welcome, {firstName}!`,
      noPlayers: "Please add music players to continue",
      subtext: `Let's rate the music of {playerName}`,
      rateButton: `Rate!`,
      changeVibeButton: `Change vibe`,
      standbyPlayer:
        "It seems like the music player is standby, or doesn't have an internet connection",
      pleaseRegister:
        "Welcome to our new web-app! Please register if you don't have an account yet or log in if you have one already",
      changeVibeText: `Change the vibe of {playerName}`,
      clickSelectPlayer: `Click here to select a music player`,
    },
    menu: {
      home: `Home`,
      players: `Players`,
      messages: `Messages`,
      help: `Help`,
      login: `Log in`,
      signup: `Sign up`,
      settings: `Settings`,
      logout: `Log out`,
      unreadMessages: `No new messages | 1 new message | {count} new messages`,
    },
    players: {
      introduction: `Edit or add a music player`,
      shareQrButton: `Share QR code`,
      deleteButton: `Delete music player`,
      descriptionAdding: `By adding a music player you can rate, <br/> or change the pace of the music`,
      descriptionQrCode: `If you have a QR code on the music player, click this option. BE AWARE the browser will ask permission for using the camera, please do this by adding the QR code.`,
      scanQrButton: `Scan QR code`,
      descriptionNoQrCode: `If you don't have a QR code, click this option`,
      noQrButton: `I don't have a <br> QR code`,
      renamePlayer: `Rename if you want to give it another name`,
      scanAgainButton: `Scan again`,
      addedSuccesfully: `Successfully added!`,
      descriptionAddMore: `Would you like to add more music players?`,
      addDifferently: `Add a player without QR Code`,
      shareQRtitle: `Share {name} with another account`,
      deleteQuestion: `Are you sure you want to delete music player {playerID}?`,
      addPlayerQR: `Add player from QR code`,
      welcomeQR: `Welcome to our website for ratings. In order to make rates and
                  add these players, please log in first, or if you don't have
                  an account yet, create an account`,
      detailTextNoQR: `To add a player manually, please fill in the details below. We will get a notification and we will handle it in one business day.`,
      whichCompany: `Company`,
      whichLocation: `Music zone (lobby, elevator). In case there is 1 music zone, please fill in the town`,
      sentRequest: `Sent! you'll receive an email in one business day`,
      fillInDetailed: `We couldn't found any players matching your company name and/or location. Please fill it in with less words.`,
    },
    messages: {
      welcome: `Hi {firstName}, your messagebox!`,
      description: `Click on one of the rates underneath to read the messages from the DJ's`,
      noMails: `No messages received yet. You will get notified once you get new messages.`,
      rateNotFound: `Rate with unknown date`,
      rateFrom: `Rate from`,
      writeMessage: `write message here`,
      welcomeContentTitle: `Welcome {firstname}!`,
      welcomeContent: `Beste {firstname},

Welkom bij de Rating app van Gallery Play Media.
Door jouw input komen we meer te weten over de smaak en muzikale wensen van jullie bedrijf.
Rode duimpjes zorgen ervoor dat de track die wordt afgespeeld direct wordt weggehaald.
Groene duimpjes geven ons waardevolle informatie om te weten te komen wat jullie wel goed vinden werken voor de sfeer ter plaatse.
Probeer je persoonlijke smaak te scheiden van de smaak van de locatie waar je je bevindt.
Wij gebruiken geen algoritmes en bekijken dus handmatig iedere rating die je doet.
      
Succes en groetjes
      
DJ’s Monte, Tommy, Thomas en Michiel`,
    },
    settings: {
      title: `Please choose the following options`,
      changeUI: `Change user info`,
      changeEM: `Change email address`,
      changePS: `Change your password`,
      delAccou: `Delete account`,
      titleEmail: `In order to change your email address, we only need to know your new address`,
      newAddrs: `New email address`,
      newAddrsC: `Please fill in again`,
      saved: `Changes have been saved!`,
      titlePassword: `In order to change your password, please login with your old details and fill in your password twice`,
      newPS: `New password`,
      newPS2nd: `Repeat new password`,
    },
    auth: {
      loginTitle: `Log in to an existing account`,
      password: `Password`,
      passwordAgain: `Confirm password`,
      enterMailHere: `Enter your email here`,
      enterPaswHere: `Enter your password here`,
      enterFirstNameHere: `Enter your first name here`,
      enterLastNameHere: `Enter your last name here`,
      enterCompanyNameHere: `Enter your company name here`,
      enterCompanyLocaHere: `Enter your company location here`,
      forgotPaswButton: `Forgotten password?`,
      forgotPaswTitle: `Forgotten your password?`,
      forgotPaswDescription: `Please enter your email address and we will sent you a new password`,
      forgotPaswSentTitle: `Mail sent!`,
      forgotPaswSentDescr: `Please enter your email address and we will send you a new password`,
      signUpTitle: `Sign up for a new account`,
      signupP2Title: `Success! Please enter following details`,
      errorPassw: `Passwords do not match`,
      errorEmptyF: `Please fill in the empty fields`,
      alreadyAccountTitle: `Welcome to our renewed platform!`,
      alreadyAccountDescription: `To continue on our new website, we need to know a bit more in order to process your account in our new platform. Please check if your email is still valid and enter a new password.`,
      alreadyAccountP2Title: `Check user details`,
      alreadyAccountP2Description: `Please fill in the information, and check if everything is still allright.`,
      alreadyAccountP3Title: `Rename music players`,
      alreadyAccountP3Description: `The last step before you can start rating! Maybe you would like to rename the music players below in your own way, please go ahead! If you want to change this later, please go the page 'players' to rename, add or delete music players.`,
      enterNewPaswHere: `Enter your NEW password here`,
      newPassword: `NEW password`,
      newPasswordAgain: `Confirm new password`,
      finishedRegistration: `Finished registration, enjoy rating!`,
    },
    general: {
      welcome: `Welcome`,
      goBack: `Go back`,
      addPlayerButton: `Add Player(s)`,
      confirmButton: `Confirm`,
      sentButton: `Sent`,
      saveChangesButton: `Save changes`,
      yesPlease: `Yes please`,
      noThanks: `No thanks`,
      or: "OR",
      close: `Close`,
      yesIAm: `Yes I am`,
      firstName: `First name`,
      lastName: `Last name`,
      companyName: `Company name`,
      companyLoca: `location (optional)`,
      notPossible: `Sorry, this is not possible at the moment. Please contact us at info@galleryplaymedia.com to help you out`,
      next: `next`,
      step: `Step`,
      cantBeEmpty: `Name properties can't be empty`,
      old: `old`,
      new: `new`,
      search: `Search`,
    },
    error: {
      permissionCamera: `Error: you need to grant camera access permission. If this is not working, please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      cameraNotFound: `Error: no camera on this device? Please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      httpsError: `Error: secure context required (HTTPS, localhost). Please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      cameraInUse: `Error: is the camera already in use? If not, please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      cameraNotSuitable: `Error: installed cameras are not suitable. Please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      cameraNotByBrowser: `Error: Using the camera is not supported by this website. Please use the camera app on your phone and scan this QR code. You will be redirected to this website.`,
      thisPlayerAlreadyAdded: `This music player is already added to your account! :)`,
      thisPlayerCannotBeFound: `There seems to be a problem finding the music player in our database. Please try again or contact us at info@galleryplaymedia.com`,
      somethingWrongTryAgain: `Something went wrong, please try again`,
    },
    help: {
      helpP: `Help page`,
      description: `Click one of the subjects or use the search function to find your question. If you cannot find your question please contact us at info@galleryplaymedia.com`,
      ThowAddPlayer: `How do I add a music player?`,
      ThowAddPlayerColleague: `You want to add a colleague at the rating app?`,
      ThowEditPlayer: `How do I change the name of a music player?`,
      ThowDeletePlayer: `How to delete a music player?`,
      TlostQRcode: `I lost the QR code, what can I do?`,
      TwhereIsQRcode: `Where is my QR code located on the music player?`,
      ThowRequestPlayer: `If I don't have a QR code, how can I make request for adding a music player?`,
      ThowCreate: `How do I create an account?`,
      TgotExistingAccount: `I already have an account on the previous system, how can I refer easily?`,
      TforgotPassword: `I forgot my password, what can I do?`,
      TchangeEmail: `I would like to change my email`,
      TchangePassword: `I would like to change my password`,
      TdeleteAccount: `I would like to delete my account`,
      TwhatIsMessages: `What is this messaging system about?`,
      TiWantToReply: `How can I reply to a message?`,
      TwhereAreUnreadMessages: `I see unread messages, where to read?`,
      Tcontact: `I have got another question`,
      TgotRequestSpecial: `I want to change something about the music`,
      DhowAddPlayer: `If you would like to add a music player, there are several options. <ul> <li class="ifList">There is a QR code at the music player</li><li class="ifList">You've received an email with a link or QR code</li><li class="ifList">A colleague who already has permission to the music player can share the QR code</li>`,
      DhowAddPlayerColleague: `Go to 'Players' from the menu. Click 'Add music player' and click the button 'Share QR code'.`,
      DhowEditPlayer: `To edit a music player, please go to the 'players' page, there you have an overview of all the registered music players. To change the name, change it in the text field and click 'Save settings'`,
      DhowDeletePlayer: `To delete a music player, please go to the 'players' page, there you have an overview of all the registered music players. To delete the music player, click 'Delete music player'`,
      DlostQRcode: `If there is no QR code at the music player there is the possibility to ask for permission. You will receive an email with a confirmation when the player is added to your account`,
      DwhereIsQRcode: `From all the music players installed after June 2020 there is a QR code located on the top of the music player. In case you don't have it, you can request them or you have already received an email with the code`,
      DhowRequestPlayer: `Go to 'Players' from the menu. Click 'Add music player' and then click the option 'I don't have a QR code'.`,
      DhowCreate: `If you want to create an account, please go to 'register' and fill in the necessary details`,
      DgotExistingAccount: `If you already had an account on the previous system, go to <a href="https://www.gpmrating.com">www.gpmrating.com</a> on the device you logged in the last time. There will be a screen to fill in the last details for a new account with the same registered music players.`,
      DforgotPassword: `Please go to 'Log in' and click the button "I forgot my password". You'll receive an email to reset your password`,
      DchangeEmail: `If you want to change your email, please go to "settings", and click the "change email address" button. Fill in your email address twice and press "save changes"`,
      DchangePassword: `Please go to "Settings" and click the "Change password" button.`,
      DdeleteAccount: `To delete your account, please go to 'Settings' and click 'Delete account'.`,
      DwhatIsMessages: `With messages you've got directly contact with the D.J.'s`,
      DiWantToReply: `When a D.J. sends a message to your rate you've got the possibility to reply on that.`,
      DwhereAreUnreadMessages: `Please go to the page 'Messages' to see your unread message.`,
      Dcontact: `Do you have another question, please let us know by sending an email to: info@galleryplaymedia.com`,
      DgotRequestSpecial: `Please send an email to info@galleryplaymedia.com or call to +31.20.2614650. Our pleasure!`,
    },
  },
  nl: {
    home: {
      welcome: `Welkom, {firstName}!`,
      noPlayers: "Voeg een muziek player toe om door te kunnen gaan",
      subtext: `Rate nu de muziek van {playerName}`,
      rateButton: `Rate!`,
      changeVibeButton: `Verander sfeer`,
      standbyPlayer:
        "Het lijkt erop dat de muziek player op standby staat, of geen internetverbinding heeft.",
      pleaseRegister:
        "Welkom op onze nieuwe web-app! Klik op registreren voor het aanmaken van een account of op inloggen als u al een account heeft",
      changeVibeText: `Verander de sfeer van {playerName}`,
      clickSelectPlayer: `Klik hier om een muziek player te selecteren`,
    },
    menu: {
      home: `Home`,
      players: `Players`,
      messages: `Berichten`,
      help: `Help`,
      login: `Inloggen`,
      signup: `Registreren`,
      settings: `Instellingen`,
      logout: `Uitloggen`,
      unreadMessages: `Geen nieuwe berichten | 1 nieuw bericht | {count} nieuwe berichten`,
    },
    players: {
      introduction: `Wijzig muziek player(s) of voeg nieuwe toe`,
      shareQrButton: `Deel QR code`,
      deleteButton: `Verwijder muziek player`,
      saveChangesButton: `Sla veranderingen op`,
      descriptionAdding: `Wanneer de muziek player is toegevoegd kun je raten of een andere sfeer selecteren`,
      descriptionQrCode: `Wanneer er een QR code op de muziek player zit, kies dan voor deze optie. WEES ALERT dat de browser toestemming vraagt voor gebruik van de camera, dit is nodig om de QR code te kunnen scannen.`,
      scanQrButton: `Scan QR code`,
      descriptionNoQrCode: `Wanneer er geen QR code op de muziek player zit, kies dan voor deze optie`,
      noQrButton: `Ik heb geen <br> QR code`,
      renamePlayer: `Wijzig de beschrijving van de muziek player naar eigen wens (optioneel)`,
      scanAgainButton: `Scan opnieuw`,
      addedSuccesfully: `Succesvol toegevoegd!`,
      descriptionAddMore: `Wil je nog meer muziek players toevoegen?`,
      addDifferently: `Voeg een muziek player toe zonder QR Code`,
      shareQRtitle: `Deel {name} met een ander account`,
      deleteQuestion: `Weet je het zeker dat je de muziek player {playerID} wilt verwijderen?`,
      addPlayerQR: `Voeg muziek player toe vanuit QR code`,
      welcomeQR: `Welkom bij de website voor ratings van Gallery Play Media. Om deze muziek player toe te kunnen voegen, wordt u gevraagd eerst in te loggen, of wanneer u nog geen account heeft, te registreren`,
      detailTextNoQR: `Om de player handmatig toe te voegen, vult u onderstaande informatie in. Wij ontvangen een melding en behandelen dit binnen één werkdag.`,
      whichCompany: `Bedrijf`,
      whichLocation: `Muziekzone (lobby, etalage, lift). Indien u 1 muziekzone heeft dan graag plaatsnaam invullen`,
      sentRequest: `Verstuurd! U ontvangt binnen max 1 werkdag antwoord van ons.`,
      fillInDetailed: `Geen players kunnen vinden op basis van de ingevulde gegevens. Probeer het nog een keer met minder woorden.`,
    },
    messages: {
      welcome: `Hallo {firstName}, je berichtenbox!`,
      description: `Klik op onderstaande ratings om de berichten te lezen van de DJ's`,
      noMails: `Nog geen berichten ontvangen, u wordt op de hoogte gesteld als er een nieuw bericht verschijnt! :-)`,
      rateNotFound: `Rating van onbekende datum`,
      rateFrom: `Rating van`,
      writeMessage: `schrijf hier een bericht`,
      welcomeContentTitle: `Welkom {firstname}!`,
      welcomeContent: `Beste {firstname},

Welkom bij de Rating app van Gallery Play Media.
Door jouw input komen we meer te weten over de smaak en muzikale wensen van jullie bedrijf.
Rode duimpjes zorgen ervoor dat de track die wordt afgespeeld direct wordt weggehaald.
Groene duimpjes geven ons waardevolle informatie om te weten te komen wat jullie wel goed vinden werken voor de sfeer ter plaatse.
Probeer je persoonlijke smaak te scheiden van de smaak van de locatie waar je je bevindt.
Wij gebruiken geen algoritmes en bekijken dus handmatig iedere rating die je doet.
      
Succes en groetjes
      
DJ’s Monte, Tommy, Thomas en Michiel`,
    },
    settings: {
      title: `Kies één van onderstaande opties`,
      changeUI: `Verander profiel informatie`,
      changeEM: `Verander email adres`,
      changePS: `Wachtwoord veranderen`,
      delAccou: `Verwijder account`,
      newAddrs: `Nieuw email adres`,
      newAddrsC: `Voer nog een keer in`,
      titleEmail: `Om uw email adres te veranderen, hebben wij alleen uw nieuwe email nodig`,
      saved: `Veranderingen zijn opgeslagen!`,
      titlePassword: `Om uw wachtwoord te veranderen, vragen wij u eerst uw oude inloggegevens in te vullen en dan het nieuwe wachtwoord twee keer in te vullen`,
      newPS: `Nieuw wachtwoord`,
      newPS2nd: `Herhaal nieuwe wachtwoord`,
    },
    auth: {
      loginTitle: `Inloggen op een bestaand account`,
      password: `Wachtwoord`,
      passwordAgain: `Herhaal wachtwoord`,
      enterMailHere: `Voer uw email adres in`,
      enterPaswHere: `Voer uw wachtwoord in `,
      enterFirstNameHere: `Voer uw voornaam in`,
      enterLastNameHere: `Voer uw achternaam in`,
      enterCompanyNameHere: `Voer uw bedrijfsnaam in`,
      enterCompanyLocaHere: `Voer uw vestiging in`,
      forgotPaswButton: `Wachtwoord vergeten?`,
      forgotPaswTitle: `Wachtwoord vergeten?`,
      forgotPaswDescription: `Voer uw email adres in en wij sturen een nieuw wachtwoord`,
      forgotPaswSentTitle: `Mail verstuurd!`,
      forgotPaswSentDescr: `U ontvangt spoedig een mail met verdere instructies`,
      signUpTitle: `Maak een nieuwe account aan`,
      signupP2Title: `Succesvol! Voer alleen nog onderstaande info in`,
      errorPassw: `Wachtwoorden zijn niet hetzelfde`,
      errorEmptyF: `Er ontbreekt nog informatie`,
      alreadyAccountTitle: `Welkom op ons nieuwe platform!`,
      alreadyAccountDescription: `Om succesvol verder te kunnen met de rating app hebben wij iets meer informatie nodig. Controleer of dit mailadres nog klopt en voer een nieuw wachtwoord in.`,
      alreadyAccountP2Title: `Controleer uw gegevens`,
      alreadyAccountP2Description: `Check of uw gegevens nog kloppen en vul eventueel ontbrekende informatie in.`,
      alreadyAccountP3Title: `Herbenoemen muziek players`,
      alreadyAccountP3Description: `De laatste stap voordat u weer kunt raten! Misschien wilt u onderstaande muziek players een andere titel geven in een eigen benoeming, dan is dit het moment! Dit kan eventueel ook later aangepast worden, bij de pagina players`,
      enterNewPaswHere: `Voer uw NIEUWE wachtwoord hier`,
      newPassword: `NIEUW wachtwoord`,
      newPasswordAgain: `Bevestig uw NIEUWE wachtwoord`,
      finishedRegistration: `Klaar met de registratie, veel plezier met de nieuwe rating app!`,
    },
    general: {
      welcome: `Welkom`,
      goBack: `Ga terug`,
      addPlayerButton: "Voeg muziek player(s) toe",
      confirmButton: `Bevestig`,
      sentButton: `Verstuur`,
      saveChangesButton: `Sla veranderingen op`,
      yesPlease: `Ja graag`,
      noThanks: `Nee bedankt`,
      or: "OF",
      close: `Sluit`,
      yesIAm: `Ja, zeker`,
      firstName: `Voornaam`,
      lastName: `Achternaam`,
      companyName: `Bedrijfsnaam`,
      companyLoca: `Vestiging (optioneel)`,
      notPossible: `Excuus, dit is op het moment nog niet mogelijk. Neem contact met ons op via info@galleryplaymedia.com om je verder te helpen`,
      next: `volgende`,
      step: `Stap`,
      cantBeEmpty: `Benoemingen kunnen niet leeg zijn`,
      old: `oud`,
      new: `nieuw`,
      search: `Zoek`,
    },
    error: {
      permissionCamera: `Error: Er is geen toegang gegeven om de camera te gebruiken op deze website. Alternatief is de QR code te scannen met de camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder toestemming te hoeven geven.`,
      cameraNotFound: `Error: Geen camera gevonden op dit apparaat? Alternatief is de QR code te scannen met de camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder error.`,
      httpsError: `Error: Geen http gebruik. Graag de QR code scannen met uw camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder error.`,
      cameraInUse: `Error: Is de camera al in gebruik? Graag de QR code scannen met uw camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder error.`,
      cameraNotSuitable: `Error: Geïnstalleerde camera's zijn niet compitabel. Graag de QR code scannen met uw camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder error.`,
      cameraNotByBrowser: `Error: De camera is niet ondersteunend bij deze browser. Graag de QR code scannen met uw camera app van uw telefoon. U wordt doorgestuurd naar deze website zonder error.`,
      thisPlayerAlreadyAdded: `Deze muziek player is al toegevoegd aan deze account! :)`,
      thisPlayerCannotBeFound: `Er is een probleem met het vinden van deze muziek player in ons systeem. Probeer het nog een keer of neem contact met ons op via info@galleryplaymedia.com`,
      somethingWrongTryAgain: `Er ging iets fout, probeer het nog een keer`,
    },
    help: {
      helpP: `Help pagina`,
      description: `Klik op één van de onderwerpen of gebruik de zoekfunctie om uw vraag te vinden. Mocht u een andere vraag hebben mail ons via info@galleryplaymedia.com`,
      ThowAddPlayer: `Hoe voeg ik een muziek player toe?`,
      ThowAddPlayerColleague: `Wil je een collega toevoegen aan de ratingapp?`,
      ThowEditPlayer: `Hoe verander ik de naam van een muziek player?`,
      ThowDeletePlayer: `Hoe verwijder ik een muziek player?`,
      TlostQRcode: `Ik ben de QR code kwijt, wat moet ik doen?`,
      TwhereIsQRcode: `Waar vindt ik mijn QR code op de muziek player?`,
      ThowRequestPlayer: `Als ik geen QR code heb, hoe kan ik alsnog toegang krijgen tot de player?`,
      ThowCreate: `Hoe maak ik een account aan?`,
      TgotExistingAccount: `Ik heb een account op het vorige systeem, hoe kan ik eenvoudig doorverwijzen?`,
      TforgotPassword: `Ik ben mijn wachtwoord vergeten.`,
      TchangeEmail: `Hoe verander ik mijn email adres?`,
      TchangePassword: `Hoe verander ik mijn wachtwoord?`,
      TdeleteAccount: `Hoe verwijder ik mijn account?`,
      TwhatIsMessages: `Wat zit er in mijn berichtenbox?`,
      TiWantToReply: `Hoe kan ik reageren op het bericht van de D.J.?`,
      TwhereAreUnreadMessages: `Ik zie ongelezen berichten, waar kan ik ze lezen?`,
      Tcontact: `Ik heb een andere vraag`,
      TgotRequestSpecial: `Ik heb een verzoek omtrent de muziek`,
      DhowAddPlayer: `Om een muziekplayer toe te kunnen voegen, zijn er verschillende opties. <ul> <li class="ifList">Er staat op je muziekplayer een QR code.</li>  <li class="ifList">Je hebt een email ontvangen met een weblink of QR code.</li> <li class="ifList">Een collega wie al toestemming heeft kan de QR code met je delen.</li>`,
      DhowAddPlayerColleague: `Ga naar ‘Players’ vanuit het menu. Klik op ‘Voeg muziek player(s) toe’ en klik op 'deel QR code'.`,
      DhowEditPlayer: `Om een muziekplayer te hernoemen, ga naar de pagina 'Players', er verschijnt een overzicht van de players die zijn geregistreerd onder uw account. U kunt deze hernoemen, vergeet niet op opslaan te drukken.`,
      DhowDeletePlayer: `Om een muziekplayer te verwijderen, ga naar de pagina 'Players', en klik op 'verwijder muziek player'.`,
      DlostQRcode: `Indien er geen QR code op de muziekplayer staat is er de mogelijkheid toestemming aan te vragen. Je ontvangt dan van ons een email met een bevestiging wanneer de player is toegevoegd.`,
      DwhereIsQRcode: `Alle muziekplayers die geïnstalleerd zijn na juni 2020 hebben een QR code op de bovenkant van de player.`,
      DhowRequestPlayer: `Ga naar ‘Players', dan naar ‘Voeg player toe' en klik op ‘Ik heb geen QR code’.`,
      DhowCreate: `Om een account aan te maken, klik op registreren en volg de stappen.`,
      DgotExistingAccount: `Mocht er al een account bestaan ga dan naar de website <a href="https://www.gpmrating.com">www.gpmrating.com</a> op het apparaat waar je ingelogd bent. Er verschijnt dan een scherm om gemakkelijk een nieuw account aan te maken met de reeds geregistreerde players.`,
      DforgotPassword: `Klik bij ‘Inloggen’ op ‘Wachtwoord vergeten'. Er wordt een email gestuurd om het wachtwoord te resetten.`,
      DchangeEmail: `Om het email adres van uw account te veranderen, ga naar 'instellingen', en klik op 'verander email adres'.`,
      DchangePassword: `Ga naar ‘Instellingen', en klik op ‘Wachtwoord veranderen'.`,
      DdeleteAccount: `Om het account te verwijderen ga naar ‘Instellingen' en klik op ‘Verwijder account'.`,
      DwhatIsMessages: `Met deze berichten heb je direct contact met de D.J.’s.`,
      DiWantToReply: `Wanneer de D.J. je een bericht stuurt kun je hierop reageren.`,
      DwhereAreUnreadMessages: `Om ongelezen berichten te lezen ga naar 'Berichten' en open het bericht.`,
      Dcontact: `Heb je toch nog een vraag, laat het ons weten! Mail gerust naar info@galleryplaymedia.com.`,
      DgotRequestSpecial: `Stuur een email naar info@galleryplaymedia.com of bel met +31.20.2614650. Graag!`,
    },
  },
};

export default messages;
