<template>
  <div class="content-container">
    <div key="content" v-if="!loading" class="wrapper">
      <transition name="fadeLR" mode="out-in">
        <div key="homeSettings" class="field" v-if="settingsPage == 0">
          <ul class="flex-outer">
            <h2>{{ $t("settings.title") }}</h2>
            <li>
              <div class="btfcColumn-full-width">
                <button class="flex-button" @click="changePage(1)">
                  {{ $t("settings.changeUI") }}
                </button>
                <button class="flex-button" @click="changePage(2)">
                  {{ $t("settings.changeEM") }}
                </button>
                <button class="flex-button" @click="changePage(3)">
                  {{ $t("settings.changePS") }}
                </button>
                <button class="flex-button" @click="changePage(4)">
                  {{ $t("settings.delAccou") }}
                </button>
              </div>
            </li>
          </ul>
        </div>
        <div key="editUserInfo" class="field" v-else-if="settingsPage == 1">
          <h3 v-if="settingsError" style="color: red">{{ settingsError }}</h3>
          <form @submit.prevent="changeUserInfo">
            <ul class="flex-outer">
              <h2>{{ $t("settings.changeUI") }}</h2>
              <li>
                <label for="first-name">{{ $t("general.firstName") }}</label>
                <input
                  v-model="user.firstname"
                  type="text"
                  id="firstname"
                  @change="changedValues"
                />
              </li>
              <li>
                <label for="last-name">{{ $t("general.lastName") }}</label>
                <input
                  v-model="user.lastname"
                  type="text"
                  id="lastname"
                  @change="changedValues"
                />
              </li>
              <li>
                <label for="last-name">{{ $t("general.companyName") }}</label>
                <input
                  v-model="user.companyname"
                  type="text"
                  id="companyname"
                  @change="changedValues"
                />
              </li>
              <li>
                <label for="last-name"
                  ><span v-html="$t('general.companyLoca')"></span
                ></label>
                <input
                  v-model="user.companylocation"
                  type="text"
                  id="companylocation"
                  @change="changedValues"
                />
              </li>
              <li>
                <div class="btfc-center">
                  <button class="flex-button" @click="changePage(0)">
                    {{ $t("general.goBack") }}
                  </button>
                  <button
                    :disabled="!shouldSaveInfo"
                    class="flex-button"
                    v-if="savedSettings == 0"
                    type="submit"
                  >
                    <div v-if="!loading">
                      {{ $t("general.saveChangesButton") }}
                    </div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </button>
                  <div v-else class="flex-button-container">
                    <font-awesome-icon icon="check" size="2x" />
                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>

        <div key="editMail" class="field" v-else-if="settingsPage == 2">
          <h3 v-if="settingsError" style="color: red">{{ settingsError }}</h3>
          <form @submit.prevent="changeEmailAddress">
            <ul class="flex-outer">
              <h2>{{ $t("settings.changeEM") }}</h2>
              <h5>{{ $t("settings.titleEmail") }}</h5>
              <li>
                <label for="newEmail">{{ $t("settings.newAddrs") }}</label>
                <input v-model="newEmail" type="email" id="email" />
              </li>
              <li>
                <label for="newEmail2nd">{{ $t("settings.newAddrsC") }}</label>
                <input v-model="newEmail2nd" type="email" id="email2nd" />
              </li>
              <li>
                <div class="btfc-center">
                  <button class="flex-button" @click="changePage(0)">
                    {{ $t("general.goBack") }}
                  </button>
                  <button
                    :disabled="!confirmedEmail"
                    class="flex-button"
                    v-if="savedSettings == 0"
                    type="submit"
                  >
                    <div v-if="!loading">
                      {{ $t("general.saveChangesButton") }}
                    </div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </button>
                  <div v-else class="flex-button-container">
                    <font-awesome-icon icon="check" size="2x" />
                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>

        <div key="resetPassword" class="field" v-else-if="settingsPage == 3">
          <form @submit.prevent="changePassword">
            <ul class="flex-outer">
              <h2>{{ $t("settings.changePS") }}</h2>
              <h5>{{ $t("settings.titlePassword") }}</h5>
              <li>
                <h3>{{ $t("menu.login") }}</h3>
              </li>
              <li>
                <label for="email">Email</label>
                <input
                  v-model="email"
                  type="email"
                  id="email"
                  :placeholder="$t('auth.enterMailHere')"
                  required
                />
              </li>
              <li>
                <label for="password"
                  >{{ $t("general.old") }} {{ $t("auth.password") }}</label
                >
                <input
                  v-model="password"
                  type="password"
                  id="password"
                  :placeholder="$t('auth.enterPaswHere')"
                  required
                />
              </li>
              <li>
                <h3>{{ $t("settings.newPS") }}</h3>
                <h3 v-if="settingsError" style="color: red">
                  {{ settingsError }}
                </h3>
              </li>
              <li>
                <label for="newEmail">{{ $t("settings.newPS") }}</label>
                <input v-model="newPS" type="password" id="password" />
              </li>
              <li>
                <label for="newEmail2nd">{{ $t("settings.newPS2nd") }}</label>
                <input v-model="newPS2nd" type="password" id="password2nd" />
              </li>
              <li>
                <div class="btfc-center">
                  <button class="flex-button" @click="changePage(0)">
                    {{ $t("general.goBack") }}
                  </button>
                  <button
                    :disabled="!confirmedPassword"
                    class="flex-button"
                    v-if="savedSettings == 0"
                    type="submit"
                  >
                    <div v-if="!loading">
                      {{ $t("general.saveChangesButton") }}
                    </div>
                    <div v-else>
                      <div class="loader"></div>
                    </div>
                  </button>
                  <div v-else class="flex-button-container">
                    <font-awesome-icon icon="check" size="2x" />
                  </div>
                </div>
              </li>
            </ul>
          </form>
        </div>

        <div key="deleteUser" class="field" v-else-if="settingsPage == 4">
          <h2>{{ $t("general.notPossible") }}</h2>
          <button class="flex-button" @click="changePage(0)">
            {{ $t("general.goBack") }}
          </button>
        </div>
      </transition>
    </div>
    <div key="loading" v-else class="wrapper">
      <div class="bigLoader">
        <h2 class=""></h2>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
function validateEmail(email, email2nd) {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email) && re.test(email2nd)) {
    return true;
  } else {
    return false;
  }
}
/* eslint-enable */

export default {
  name: "settings",
  data: function () {
    return {
      settingsPage: 0,
      savedSettings: 0,
      shouldSaveInfo: null,
      confirmedEmail: null,
      newEmail: null,
      newEmail2nd: null,
      confirmedPassword: false,
      email: null,
      password: null,
      newPS: null,
      newPS2nd: null,
    };
  },
  computed: {
    user() {
      return {
        firstname: this.$store.state.user.firstname,
        lastname: this.$store.state.user.lastname,
        companyname: this.$store.state.user.companyname,
        companylocation: this.$store.state.user.companylocation,
      };
    },
    loading() {
      return this.$store.state.loading;
    },
    settingsError() {
      return this.$store.state.error;
    },
  },
  methods: {
    changePage(payload) {
      this.$store.commit("clearError");
      this.settingsPage = payload;
      this.savedSettings = 0;
    },
    changedValues() {
      this.shouldSaveInfo = 1;
      this.savedSettings = 0;
    },
    changeUserInfo() {
      if (
        this.user.firstname !== null &&
        this.user.firstname !== undefined &&
        this.user.lastname !== null &&
        this.user.lastname !== undefined &&
        this.user.companyname !== null &&
        this.user.companyname !== undefined
      ) {
        this.$store.commit("clearError");
        const firstnameC = this.capitalized(this.user.firstname);
        const lastnameC = this.capitalized(this.user.lastname);
        const companynameC = this.capitalized(this.user.companyname);
        const companylocationC = this.capitalized(this.user.companylocation);
        this.$store.dispatch("changeUserInfo", {
          firstname: firstnameC,
          lastname: lastnameC,
          companyname: companynameC,
          companylocation: companylocationC,
        });
        this.savedSettings = 1;
      } else {
        this.$store.commit("changeError", "Please fill in the empty fields");
      }
    },
    changedEmailValues() {
      if (
        validateEmail(this.newEmail, this.newEmail2nd) &&
        this.newEmail.toUpperCase() == this.newEmail2nd.toUpperCase()
      ) {
        this.confirmedEmail = true;
      } else {
        this.confirmedEmail = false;
      }
    },
    changeEmailAddress() {
      this.$store.commit("clearError");
      if (!validateEmail(this.newEmail, this.newEmail2nd)) {
        this.$store.commit("changeError", "Email address is not valid");
      } else {
        this.$store.dispatch("changeEmailUser", {
          email: this.newEmail,
        });
        if (this.settingsError == null) {
          this.changePage(0);
        }
      }
    },
    changePassword() {
      this.$store.commit("clearError");
      if (!this.newPS == this.newPS2nd) {
        this.$store.commit("changeError", "Passwords are not the same");
      } else {
        this.$store.dispatch("changePassword", {
          email: this.email,
          oldPS: this.password,
          password: this.newPS,
        });
      }
    },
    capitalized: function (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  beforeCreate() {
    this.$store.commit("clearError");
  },
  created() {},
  watch: {
    newEmail() {
      this.changedEmailValues();
    },
    newEmail2nd() {
      this.changedEmailValues();
    },
    newPS() {
      if (this.newPS == this.newPS2nd) {
        this.confirmedPassword = true;
      } else {
        this.confirmedPassword = false;
      }
    },
    newPS2nd() {
      if (this.newPS == this.newPS2nd) {
        this.confirmedPassword = true;
      } else {
        this.confirmedPassword = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
