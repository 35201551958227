/* eslint-disable no-console */

import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('');
      // console.log(
      // "App is being served from cache by a service worker.\n" +
      //   "For more details, visit https://goo.gl/AFskqB"
      // );
    },
    registered() {
      console.log('');
      // console.log("Service worker has been registered.");
    },
    cached() {
      console.log('');
      // console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log('');
      // console.log("New content is downloading.");
    },
    updated() {
      console.log('');
      // console.log("New content is available; please refresh the page thingie.");
      // Added automatic refreshing
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    },
    offline() {
      console.log('');
      // console.log(
      // "No internet connection found. App is running in offline mode."
      //     );
      // Tryout making an offline content page
      //const body = document.getElementsByTagName('body')[0];
      //body.appendChild(script);
      //body.appendChild(noscript);
      var content = "This website no works offline";
      document.getElementsByTagName("body")[0].innerHTML = content;
    },
    error(error) {
      console.log(error);
      // console.error("Error during service worker registration:", error);
    },
  });
}
