import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Vuex from "vuex";
import Router from "./components/router.vue";
import i18n from "./components/i18n.js";
import { store } from "./stores/store.js";
import vSelect from "vue-select";
import VModal from "vue-js-modal";
//import VueQrcode from '@chenfengyuan/vue-qrcode'
import firebase from "firebase/app";
import "firebase/auth";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMale,
  faCoffee,
  faUtensils,
  faCocktail,
  faMoon,
  faPowerOff,
  faCircle,
  faBell,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faUserCog,
  faInfoCircle,
  faEnvelope,
  faHdd,
  faHome,
  faSlidersH,
  faShare,
  faPlus,
  faMinus,
  faQrcode,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faCheck,
  faBed,
  faArrowRight,
  faSatelliteDish,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

// fontawesome
library.add(
  faPowerOff,
  faCircle,
  faBell,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faUserCog,
  faInfoCircle,
  faEnvelope,
  faHdd,
  faHome,
  faSlidersH,
  faShare,
  faPlus,
  faMinus,
  faQrcode,
  faTrash,
  faThumbsUp,
  faThumbsDown,
  faCheck,
  faBed,
  faArrowRight,
  faSatelliteDish,
  faTimes
);
// Bonus underneath for vibes
library.add(faMale, faCoffee, faUtensils, faCocktail, faMoon);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.component("v-select", vSelect);
//Vue.component(VueQrcode.name, VueQrcode);
import "vue-select/dist/vue-select.css";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VModal, { dialog: true });
Vue.use(require("vue-moment"));

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes: Router,
  mode: "history",
});

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
  created() {
    firebase.initializeApp({
      apiKey: process.env.VUE_APP_APIKEY,
      authDomain: process.env.VUE_APP_AUTHDO,
      databaseURL: process.env.VUE_APP_AURL,
      projectId: process.env.VUE_APP_PROJC,
      storageBucket: "",
      messagingSenderId: process.env.VUE_APP_SENDID,
      appId: process.env.VUE_APP_APPID,
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (
        user &&
        this.$store.state.user.registered == null &&
        this.$store.state.user.registered == undefined
      ) {
        this.$store.dispatch("autoSignIn", user);
        this.$store.dispatch("fetchUserData");
      } else {
        this.$store.commit("setLoading", false);
      }
    });
  },
}).$mount("#app");
