<template>
	<a class="switchCube" v-bind:class="{ activePlaying: active }">
		<div class="switchContent" v-if="containsHuman(icon) !== -1">
			<font-awesome-layers>
				<font-awesome-icon
					v-for="i in parseInt(getIntHumans(icon))"
					:key="i"
					:transform="transformHuman[parseInt(getIntHumans(icon))][i]"
					icon="male"
					style="color:white;padding-top:10px"
					:size="getSizeIcons()"
				></font-awesome-icon>
			</font-awesome-layers>
			<h3>{{ replaceUnderscores(name) }}</h3>
		</div>
		<div class="switchContent" v-else-if="containsLogo(icon) !== -1">
			<div class="svgContainer" v-html="getSVGlogo(icon)"></div>
			<h3>{{ replaceUnderscores(name) }}</h3>
		</div>
		<div class="switchContent" v-else>
			<font-awesome-icon
				:icon="icon"
				style="color:white;padding-top:10px"
				:size="getSizeIcons()"
			></font-awesome-icon>
			<h3>{{ replaceUnderscores(name) }}</h3>
		</div>
	</a>
</template>
<script>
import logo_json from "../../assets/svg_logo.json";
// CONVERTER: https://www.pngtosvg.com/
// DELETE ONLY BACKGROUND LAYER
// MAKE WIDTH AND HEIGHT 90%

export default {
	name: "switchOptionBox",
	data: function() {
		return {
			transformHuman: {
				1: [""],
				2: ["", "right-6 shrink-2"],
				3: ["", "left-6 shrink-2", "right-6 shrink-2"],
				4: ["", "right-5 shrink-2", "left-5 shrink-2", "right-9 shrink-3"],
				5: [
					"",
					"right-5 shrink-2",
					"left-9 shrink-3",
					"right-9 shrink-3",
					"left-5 shrink-2",
				],
			},
		};
	},
	props: ["name", "icon", "stamp", "active"],
	methods: {
		replaceUnderscores(string) {
			return string.replace(/_/g, " ");
		},

		getIntHumans(string) {
			return string.replace("human", "");
		},
		containsLogo(string) {
			var value = string.indexOf("_logo") || "-1";
			return value;
		},
		containsHuman(string) {
			var value = string.indexOf("human") || "-1";
			return value;
		},
		getSizeIcons() {
			if (window.innerWidth < 479) {
				return "4x";
			} else {
				return "5x";
			}
		},
		getSVGlogo(logo) {
			logo = logo.replace("_logo", "");
			return logo_json[logo];
		},
	},
};
</script>

<style scoped>
@media screen and (min-width: 479px) {
	.switchCube {
		width: 10em;
		height: 10em;
		border: 0px;
		margin: 8px;
		display: flex;
		border-radius: 10px;
		background: #333;
	}
	#svg {
		width: 8em !important;
	}
	.svgContainer {
		margin-top: 15px;
	}
}
@media screen and (min-width: 351px) and (max-width: 478px) {
	.switchContent > h3 {
		font-size: 15px;
	}
	#svg {
		width: 6em !important;
	}
	.svgContainer {
		margin-top: 13px;
	}
}
@media screen and (min-width: 351px) and (max-width: 399px) {
	.switchCube {
		width: 8.5em;
		height: 8.5em;
		border: 0px;
		margin: 8px;
		display: flex;
		border-radius: 10px;
		background: #333;
	}
}
@media screen and (min-width: 400px) and (max-width: 439px) {
	.switchCube {
		width: 9em;
		height: 9em;
		border: 0px;
		margin: 8px;
		display: flex;
		border-radius: 10px;
		background: #333;
	}
}
@media screen and (min-width: 440px) and (max-width: 478px) {
	.switchCube {
		width: 9.5em;
		height: 9.5em;
		border: 0px;
		margin: 8px;
		display: flex;
		border-radius: 10px;
		background: #333;
	}
}

@media screen and (max-width: 350px) {
	.switchCube {
		width: 7em;
		height: 7em;
		border: 0px;
		margin: 8px;
		display: flex;
		border-radius: 10px;
		background: #333;
	}
	.switchContent > h3 {
		font-size: 13px;
	}
	#svg {
		width: 5em !important;
	}
	.svgContainer {
		margin-top: 10px;
	}
}
.switchContent {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}
.switchContent > .fa-layers {
	width: 100%;
	height: 100%;
}

.switchCube h3 {
	color: #f2f2f2;
	text-transform: uppercase;
	letter-spacing: 0.09em;
	font-weight: 500;
}
.activePlaying {
	background: rgba(51, 51, 51, 0.2) !important;
	pointer-events: none;
}
.switchCube:hover {
	background-color: #1a1a1a;
}
svg {
	display: relative !important;
	top: 10px;
}
</style>
