<script>
import Home from "./home.vue";
//import Players from './players.vue'
import Messages from "./messages.vue";
import Help from "./help.vue";
import Login from "./auth/login.vue";
import Signup from "./auth/signup.vue";
import SignupP2 from "./auth/signupP2.vue";
import Settings from "./settings.vue";
import PasswordForgotten from "./auth/passwordForgotten.vue";
import alreadyAccount from "./auth/alreadyAccount.vue";
import alreadyAccountP2 from "./auth/alreadyAccountP2.vue";
import alreadyAccountP3 from "./auth/alreadyAccountP3.vue";
import addPlayerQR from "./addPlayerQR.vue";
import auth_guard from "./auth/auth_guard";
import fromOldWebsite from "./auth/fromOldWebsite";
import damin from "./damin.vue";

//lazyLoad players
function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

export default [
  {
    name: "start",
    path: "/",
    //redirect: "/home",
    component: Home,
    beforeEnter: fromOldWebsite,
  },
  {
    name: "home",
    path: "/home",
    component: Home,
    beforeEnter: fromOldWebsite,
  },
  {
    name: "players",
    path: "/players",
    component: lazyLoad("players"),
    beforeEnter: auth_guard,
  },
  {
    name: "messages",
    path: "/messages",
    component: Messages,
    beforeEnter: auth_guard,
  },
  {
    name: "help",
    path: "/help",
    component: Help,
    beforeEnter: auth_guard,
  },
  {
    name: "settings",
    path: "/settings",
    component: Settings,
    beforeEnter: auth_guard,
  },
  {
    name: "login",
    path: "/login",
    component: Login,
    beforeEnter: fromOldWebsite,
  },
  {
    name: "signup",
    path: "/signup",
    component: Signup,
    beforeEnter: fromOldWebsite,
  },
  {
    name: "signupP2",
    path: "/signupP2",
    component: SignupP2,
  },
  {
    name: "passwordForgotten",
    path: "/passwordForgotten",
    component: PasswordForgotten,
  },
  {
    name: "alreadyAccount",
    path: "/alreadyAccount",
    component: alreadyAccount,
  },
  {
    name: "alreadyAccountP2",
    path: "/alreadyAccountP2",
    component: alreadyAccountP2,
  },
  {
    name: "alreadyAccountP3",
    path: "/alreadyAccountP3",
    component: alreadyAccountP3,
  },
  {
    name: "addPlayerQR",
    path: "/addPlayerQR/:playerID",
    component: addPlayerQR,
    //beforeEnter: auth_guard,
  },
  {
    name: "homepage",
    path: "/addPlayerQR",
    component: Home,
  },
  {
    name: "damin",
    path: "/damin",
    component: damin,
  },
];
</script>
