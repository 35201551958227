<template>
  <li v-bind:class="isUser(from)">
    <div class="bubble">
      <div class="from">
        {{ from }}: <span class="msg"> {{ msg }} </span>
      </div>
      <span class="date">
        {{ date | moment("from", "now") }}
      </span>
    </div>
  </li>
</template>
<script>
export default {
  name: "messageContainer",
  props: ["from", "date", "rec", "msg"],
  methods: {
    intToDate(int) {
      var myDate = new Date(int);
      return myDate.toLocaleString();
    },
    isUser(from) {
      var loggedIn = this.$store.state.user.firstname;
      return loggedIn == from ? "messageOwn" : "message";
    },
  },
};
</script>

<style scoped>
.message {
  overflow: auto;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  max-width: 95%;
  white-space: pre-wrap;
  text-align: left;
}
.messageOwn {
  overflow: auto;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}

.messageOwn > .bubble {
  background-color: rgba(127, 191, 63, 0.3);
}

.message > .bubble {
  background-color: rgba(63, 127, 191, 0.3);
}
.bubble {
  padding: 4px;
  margin: 3px;
  padding-left: 1em;
  padding-right: 1em;
  border: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.from {
  max-width: 100%;
  font-weight: 300;
}
.msg {
  font-weight: 500;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.date {
  margin-left: 8px;
  font-weight: 300;
  font-size: 10px;
  color: black;
}
</style>
