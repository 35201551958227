import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import i18n from "../components/i18n.js";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    aPage: 0,
    allPlayers: null,
    isNavOpen: false,
    error: null,
    loading: true,
    passwordForgot: false,
    user: {
      id: null,
      registeredPlayers: [],
      registered: null,
      totalMessages: [],
      unreadMessages: 0,
    },
    playerPage: 0,
    newPlayer: [],
    selectedPlayer: null,
    selectedIfPlaying: true,
    selectedIfSwitchi: false,
    rateOrChangeMood: false,
    currentRateOfMsg: false,
    selectedSwitchOpt: [],
    switchLoading: true,
    sentMessageLoading: false,
    notification: { show: false, icon: "", title: "", text: "" },
    ref: null,
    holdAddPlayer: null,
    loaderRate: false,
  },
  actions: {
    signUserUp({ commit }, payload) {
      commit("setLoading", true);
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((well) => {
          commit("setLoading", false);
          const newUser = {
            id: well.user.uid,
            registered: 1,
            registeredPlayers: [],
            totalMessages: [],
            unreadMessages: 0,
          };
          commit("setUser", newUser);
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("changeError", error.message);
        });
    },
    signUserUpP2({ commit }, output) {
      commit("setLoading", true);
      const newUser = {
        id: store.state.user.id,
        firstname: output.firstname,
        lastname: output.lastname,
        companyname: output.companyname,
        companylocation: output.companylocation,
        registeredPlayers: [],
        registered: null,
        totalMessages: [],
        unreadMessages: 0,
      };
      firebase
        .database()
        .ref("/users/" + store.state.user.id + "/info/")
        .set(newUser);
      commit("setUser", newUser);
      commit("setLoading", false);
      store.dispatch("addWelcomeMessage", output.firstname);
    },
    signUserUpFromOld({ commit }, payload) {
      commit("setLoading", true);
      // Make auth
      commit("setUser", {
        registered: 2,
      });
      firebase
        .auth()
        .createUserWithEmailAndPassword(payload.email, payload.password)
        .then((well) => {
          // Get player information
          const registeredPlayers = [];
          if (
            payload.regplayers !== undefined &&
            payload.regplayers !== null &&
            payload.regplayers !== ""
          ) {
            const regPlayers = payload.regplayers.split(",");
            var collectPlayers = new Promise((resolve) => {
              regPlayers.forEach((v, index, array) => {
                // retreive information of player
                if (v !== "PRIVALL") {
                  firebase
                    .database()
                    .ref("/players/")
                    .orderByChild("playerId")
                    .equalTo(parseInt(v))
                    .once("value")
                    .then((data) => {
                      if (data.exists()) {
                        registeredPlayers[
                          Object.values(data.val())[0].crypKey
                        ] = {
                          id: Object.values(data.val())[0].playerId,
                          location: Object.values(data.val())[0].address,
                          name: Object.values(data.val())[0].location,
                          crypKey: Object.values(data.val())[0].crypKey,
                          switchEnabled: Object.values(data.val())[0]
                            .switchEnabled,
                        };
                        if (index === array.length - 1) {
                          resolve();
                        }
                      }
                    });
                }
              });
            });
          }
          collectPlayers.then(() => {
            // Add user details
            const newUser = {
              info: {
                id: well.user.uid,
                firstname: payload.firstname,
                lastname: payload.lastname,
                companyname: payload.company,
                companylocation: "",
                registered: 1,
                totalMessages: [],
                unreadMessages: 0,
              },
              registeredPlayers: registeredPlayers,
            };
            firebase
              .database()
              .ref("/users/" + well.user.uid)
              .set(newUser);
            commit("setUser", newUser.info);
            commit("setLoading", false);
            // Delete old cookies
            var cookies = document.cookie.split(";");

            for (var i = 0; i < cookies.length; i++) {
              var cookie = cookies[i];
              var eqPos = cookie.indexOf("=");
              var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
              document.cookie =
                name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
          });
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("changeError", error.message);
        });
    },
    onSignIn({ commit }, payload) {
      commit("setLoading", true);
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email, payload.password)
        .then((well) => {
          commit("setLoading", false);
          const newUser = {
            id: well.user.uid,
            registered: null,
            registeredPlayers: [],
          };
          commit("setUser", newUser);
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("changeError", error.message);
        });
    },
    onDaminSignIn({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setLoading", true);
        firebase
          .auth()
          .signInWithEmailAndPassword(payload.email, payload.password)
          .then(() => {
            commit("setLoading", false);
            firebase
              .database()
              .ref("/users/" + store.state.user.id + "/info/")
              .once("value")
              .then((data) => {
                if (data.val().damin == true) {
                  // make all players available as list
                  firebase
                    .database()
                    .ref("/players/")
                    .once("value")
                    .then((data) => {
                      console.log(Object.values(data.val()));
                      commit("changeApage", 1);
                      commit("changeAllPlayers", Object.values(data.val()));
                      resolve();
                    });
                }
              });
          })
          .catch((error) => {
            commit("setLoading", false);
            commit("changeError", error.message);
          });
      });
    },
    onPasswordForgot({ commit }, payload) {
      commit("setLoading", true);
      firebase
        .auth()
        .sendPasswordResetEmail(payload.email)
        .then((well) => {
          if (well != undefined) {
            ("");
          }
          commit("setLoading", false);
          commit("setPasswordForgot", true);
        })
        .catch((error) => {
          commit("setLoading", false);
          commit("changeError", error.message);
        });
    },
    autoSignIn({ commit }, payload) {
      commit("setUser", {
        id: payload.uid,
        registered: null,
        registeredPlayers: [],
      });
    },
    fetchUserData({ commit, getters }) {
      commit("setLoading", true);
      const basicInfo = this.state.user;
      firebase
        .database()
        .ref("/users/" + getters.user + "/")
        .once("value")
        .then((data) => {
          const resultI = data.val().info;
          let registeredPlayers = {};
          if ("registeredPlayers" in data.val()) {
            registeredPlayers = Object.values(data.val().registeredPlayers);
          }
          const userExtraInfo = {
            id: basicInfo["id"],
            firstname: resultI["firstname"],
            lastname: resultI["lastname"],
            companyname: resultI["companyname"],
            companylocation: resultI["companylocation"],
            registeredPlayers: registeredPlayers,
            registered: null,
            totalMessages: [],
            unreadMessages: 0,
          };
          commit("setLoading", false);
          commit("setUser", userExtraInfo);
          this.dispatch("getUnreadMessages", userExtraInfo["id"]);
        });
      /*.catch(
                error => {
                    commit('setLoading', false)
                    commit('changeError', 'Something went wrong, sorry!')
            }) */
    },
    logout({ commit }) {
      firebase.auth().signOut();
      const newUser = {
        id: null,
        registeredPlayers: [],
        registered: null,
      };
      commit("setUser", newUser);
    },
    changeEmailUser({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      var user = firebase.auth().currentUser;
      user
        .updateEmail(payload.email)
        .then(function () {
          commit("setNotification", {
            show: true,
            icon: "success",
            title: i18n.t("settings.saved"),
          });
        })
        .catch(function (error) {
          commit(
            "changeError",
            i18n.t("error.somethingWrongTryAgain") + ": " + error
          );
        });
      commit("setLoading", false);
    },

    changePassword({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      var user = firebase.auth().currentUser;
      var newPassword = payload.password;
      var credential = firebase.auth.EmailAuthProvider.credential(
        payload.email,
        payload.oldPS
      );
      user
        .reauthenticateWithCredential(credential)
        .then(function () {
          user
            .updatePassword(newPassword)
            .then(function () {
              commit("setNotification", {
                show: true,
                icon: "success",
                title: i18n.t("settings.saved"),
              });
            })
            .catch(function (error) {
              commit("changeError", error);
            });
        })
        .catch(function (error) {
          commit("changeError", error);
        });
      commit("setLoading", false);
    },
    sentQRcode({ commit }, payload) {
      setTimeout(function () {
        commit("newPlayer", []);
        commit("clearError");
        commit("setLoading", true);
        firebase
          .database()
          .ref("/players/")
          .orderByChild("crypKey")
          .equalTo(
            payload.result.replace(
              "https://gpmrating.com/addPlayerQR/",
              ""
            )
          )
          .once("value")
          .then((data) => {
            if (!data.exists()) {
              commit("changeError", i18n.t("error.thisPlayerCannotBeFound"));
              commit("setLoading", false);
              commit("changePlayerPage", 2);
            } else {
              // Check if already added to list
              const newPlayer = {
                id: Object.values(data.val())[0].playerId,
                name: Object.values(data.val())[0].location,
                address: Object.values(data.val())[0].address,
                crypKey: Object.values(data.val())[0].crypKey,
                switchEnabled: Object.values(data.val())[0].switchEnabled,
              };
              commit("newPlayer", newPlayer);
              commit("setLoading", false);
              commit("changePlayerPage", 3);
            }
          });
      }, 1000);
      /*.catch(
                error => {
                    commit('changeError', 'Player ID not found, please try again')
                    commit('setLoading', false)
                    commit('changePlayerPage',2)
            }) */
    },
    addNewPlayer({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      const playerAdd = {
        id: store.state.newPlayer.id,
        location: store.state.newPlayer.address,
        name: payload.name,
        crypKey: store.state.newPlayer.crypKey,
        switchEnabled: store.state.newPlayer.switchEnabled,
      };
      firebase
        .database()
        .ref("/users/" + store.state.user.id + "/registeredPlayers/")
        .push(playerAdd);
      store.dispatch("fetchUserData");
      commit("setLoading", false);
    },
    editPlayerList({ commit }, payload) {
      commit("setLoading", true);
      payload.input.forEach(function (e) {
        firebase
          .database()
          .ref("/users/" + store.state.user.id + "/registeredPlayers/")
          .orderByChild("crypKey")
          .equalTo(e[1])
          .once("value", function (snapshot) {
            snapshot.forEach(function (child) {
              child.ref.update({ name: e[0] });
            });
          });
      });
      store.dispatch("fetchUserData");
      commit("selectedPlayer", null);
      commit("setLoading", false);
    },
    deletePlayer({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setLoading", true);
        const ref = firebase
          .database()
          .ref("/users/" + store.state.user.id + "/registeredPlayers/");
        ref
          .orderByChild("crypKey")
          .equalTo(payload.crypKey)
          .once("value", (snapshot) => {
            const updates = {};
            snapshot.forEach((child) => (updates[child.key] = null));
            ref.update(updates).then(function () {
              store.dispatch("fetchUserData").then(function () {
                commit("setLoading", false);
                commit("selectedPlayer", null);
                resolve();
              });
            });
          });
      });
    },
    moveToMoodSwitch({ commit }) {
      if (store.state.selectedPlayer !== null) {
        commit("rateOrChangeMood", true);
      }
    },
    moveToRatePage({ commit }) {
      commit("rateOrChangeMood", false);
    },
    switchSelectedPlayer({ commit }, payload) {
      if (store.state.selectedPlayer !== null) {
        let ref = firebase
          .database()
          .ref("/players/")
          .orderByChild("crypKey")
          .equalTo(store.state.selectedPlayer.crypKey);
        ref.off("value");
      }
      if (payload !== undefined && payload !== null && payload !== "") {
        commit("selectedPlayer", payload);
        let ref = firebase
          .database()
          .ref("/players/")
          .orderByChild("crypKey")
          .equalTo(payload.crypKey);
        ref.on("value", function (data) {
          const arr = Object.values(data.val())[0];
          if (!data.exists()) {
            commit("changeError", i18n.t("error.thisPlayerCannotBeFound"));
            commit("selectedPlayer", null);
          } else {
            commit("selectedIfSwitchi", arr.switchEnabled);
            commit("selectedIfPlaying", arr.ifPlaying);
            if (arr.switchObj && arr.switchObj.length) {
              arr.switchObj = arr.switchObj.filter(function () {
                return arr.switchObj !== undefined;
              });
              arr.switchObj.sort(sortFunction);
            }
            commit("selectedSwitchOpt", arr.switchObj);
          }
        });
      } else {
        commit("selectedPlayer", null);
      }
    },
    ratePlayer({ commit, dispatch }, payload) {
      //commit('setLoading',true)
      commit("setNotification", {
        show: true,
        icon: "loading",
        title: "Sending task to player",
      });
      // get Key element first
      firebase
        .database()
        .ref("/players/")
        .orderByChild("playerId")
        .equalTo(parseInt(payload.id))
        .once("value")
        .then((data) => {
          const key = Object.keys(data.val())[0];
          const feedback = {
            rateType: payload.rate,
            user: store.state.user.id,
            userName:
              store.state.user.firstname + " " + store.state.user.lastname,
            dateAsked: Date.now(),
            receivedTask: false,
            returnedInfo: [],
          };
          dispatch("setTimer");
          store.state.loaderRate = true;
          firebase
            .database()
            .ref("/players/" + key + "/rates/")
            .set(feedback)
            .then(function () {
              const receivedRef = firebase
                .database()
                .ref("/players/" + key + "/rates/receivedTask");
              receivedRef.on("value", function (data) {
                if (data.val() == true) {
                  // received by player
                  receivedRef.off("value");
                  store.state.loaderRate = false;
                  commit("setLoading", false);
                  commit("setNotification", {
                    show: true,
                    icon: "done",
                    title: "Succesvol! Wij gaan hiermee aan de slag!",
                    text: "Succesvol ontvangen bij de muziek player",
                  });
                }
              });
            });
        });
    },
    setTimer({ commit }) {
      /* eslint-disable */
      let timer = setTimeout(function () {
        if (store.state.loaderRate == true) {
          commit("setNotification", {
            show: true,
            icon: "loadError",
            title: "Oops!",
            text:
              "Something went wrong, we cannot connect to the player. Please check the internet connection of the music player or you can contact us at +31 20 261 46 50",
          });
        }
      }, 3000);
      /* eslint enable */
    },
    changeStyle({ commit }, payload) {
      commit("setNotification", {
        show: true,
        icon: "loading",
        title: "Sending task to player",
      });
      //firebase.database().ref('/players/').orderByChild('playerId').equalTo(payload.id).once('value')
      firebase
        .database()
        .ref("/players/")
        .orderByChild("playerId")
        .equalTo(parseInt(payload[0]))
        .once("value")
        .then((data) => {
          const key = Object.keys(data.val())[0];
          const styleArray = Object.values(data.val())[0]["switchObj"];
          var result = styleArray.map(function (el) {
            var o = Object.assign({}, el);
            o.active = "active";
            return o;
          });
          const feedback = {
            playerId: payload[0],
            stampID: payload[2],
            name: payload[1],
            receivedTask: false,
          };
          firebase
            .database()
            .ref("/players/" + key + "/switcher/")
            .set(feedback);
          firebase
            .database()
            .ref("/players/" + key + "/switchObj/")
            .set(result)
            .then(function () {
              const receivedRef = firebase
                .database()
                .ref("/players/" + key + "/switcher/receivedTask");
              receivedRef.on("value", function (data) {
                if (data.val() == true) {
                  // received by player
                  receivedRef.off("value");
                  commit("setLoading", false);
                  commit("setNotification", {
                    show: true,
                    icon: "done",
                    title: "Success!",
                    text: "succesfully changed playlist",
                  });
                }
              });
            });
        });
    },
    changeUserInfo({ commit }, payload) {
      commit("setLoading", true);
      commit("clearError");
      var updates = {};
      updates["/firstname"] = payload.firstname;
      updates["/lastname"] = payload.lastname;
      updates["/companyname"] = payload.companyname;
      updates["/companylocation"] = payload.companylocation;
      firebase
        .database()
        .ref("/users/" + store.state.user.id + "/info/")
        .update(updates, function (error) {
          if (error) {
            commit("changeError", i18n.t("error.somethingWrongTryAgain"));
          } else {
            store.dispatch("fetchUserData");
            commit("changeRegisteredPost", 3);
          }
        });
      commit("setLoading", false);
    },
    getMessages({ commit }, payload) {
      //commit('setLoading', true)
      let refSwitch = firebase
        .database()
        .ref("/users/" + payload + "/messages/");
      refSwitch.off("value");
      refSwitch.on("value", (snapshot) => {
        commit("clearError");
        var output = [];
        var count = 0;
        var unreadTotal = 0;
        snapshot.forEach((child) => {
          var rating = child.key;
          var childData = [];
          let unreadMsgs = 0;
          var ratingInfo = [];
          var countValue = 0;
          child.forEach((value) => {
            if (value.key !== "info") {
              childData.push(value.val());
              if (value.val()["rcvd"] == false) {
                unreadMsgs++;
                unreadTotal++;
              }
            } else {
              ratingInfo = {
                type: value.val()["type"],
                date: value.val()["dateTime"],
              };
            }
            countValue++;
            if (countValue == child.numChildren()) {
              if (Object.keys(childData).length > 0) {
                output.push({
                  rate: rating,
                  messages: childData,
                  unread: unreadMsgs,
                  rateInfo: ratingInfo,
                });
                output.sort((a, b) =>
                  a["messages"][a["messages"].length - 1]["created"] <
                    b["messages"][b["messages"].length - 1]["created"]
                    ? 1
                    : -1
                );
              }
            }
          });
          count++;
          if (count == snapshot.numChildren()) {
            commit("setUnreadMsgs", unreadTotal);
            commit("setMessages", output);
          }
        });
      });
    },
    addMessage({ commit }, message) {
      if (message) {
        commit("clearError");
        const info = {
          created: Date.now(),
          from: this.state.user.firstname,
          msg: message,
          rcvd: true,
        };
        firebase
          .database()
          .ref(
            "/users/" +
            store.state.user.id +
            "/messages/" +
            store.state.currentRateOfMsg
          )
          .child(Date.now())
          .update(info);
      }
    },
    addWelcomeMessage({ commit }, firstname) {
      commit("clearError");
      const welcomename = { firstname: firstname };
      const info = {
        created: Date.now(),
        from: 'Gallery Play Media',
        msg: i18n.t("messages.welcomeContent", welcomename),
        rcvd: false,
      };
      firebase
        .database()
        .ref(
          "/users/" +
          store.state.user.id +
          "/messages/R00000"
        )
        .child(Date.now())
        .update(info);
      setTimeout(() => { commit("setUnreadMsgs", 1) }, 1000);
    },
    dispSwitchCurrentRateMsg({ commit }, payload) {
      commit("switchCurrentRateMsg", payload);
      // make all unread messages read
      // first update realtime off
      store.state.user.totalMessages.forEach((child) => {
        if (child.rate == payload) {
          child.messages.forEach((msg) => {
            if (msg.rcvd == false) {
              firebase
                .database()
                .ref("/users/" + store.state.user.id + "/messages/" + payload)
                .once("value", function (snapshot) {
                  snapshot.forEach(function (child) {
                    child.ref.update({
                      rcvd: true,
                    });
                  });
                });
              store.state.user.totalMessages.forEach((value, index, arr) => {
                if (value["rate"] == payload) {
                  arr[index]["unread"] = 0;
                }
              });
              //this.dispatch('getMessages',store.state.user.id)
            }
          });
        }
      });
    },
    getUnreadMessages({ commit }, payload) {
      let ref = firebase.database().ref("/users/" + payload + "/messages/");
      ref.once("value", (snapshot) => {
        var unreadTotal = 0;
        snapshot.forEach((child) => {
          child.forEach((value) => {
            if (value.key !== "info") {
              if (value.val()["rcvd"] == false) {
                unreadTotal++;
              }
            }
          });
        });
        commit("setUnreadMsgs", unreadTotal);
      });
    },
    initHoldAddPlayer({ commit }, payload) {
      commit("newPlayer", []);
      if (payload !== undefined && payload !== null && payload !== "") {
        commit("changeHoldAddPlayer", payload);
        firebase
          .database()
          .ref("/players/")
          .orderByChild("crypKey")
          .equalTo(payload)
          .once("value")
          .then((data) => {
            if (!data.exists()) {
              commit("changeError", i18n.t("error.thisPlayerCannotBeFound"));
            } else {
              // Check if already added to list
              if (
                store.state.user.id !== undefined &&
                store.state.user.id !== null &&
                store.state.user.id !== ""
              ) {
                firebase
                  .database()
                  .ref("/users/" + store.state.user.id + "/registeredPlayers/")
                  .orderByChild("crypKey")
                  .equalTo(payload)
                  .once("value")
                  .then((data) => {
                    if (data.val() !== null) {
                      commit(
                        "changeError",
                        i18n.t("error.thisPlayerAlreadyAdded") //"This player is already added to your account! :)"
                      );
                    }
                  });
              }
              const newPlayer = {
                id: Object.values(data.val())[0].playerId,
                name: Object.values(data.val())[0].location,
                address: Object.values(data.val())[0].address,
                crypKey: Object.values(data.val())[0].crypKey,
                switchEnabled: Object.values(data.val())[0].switchEnabled,
              };
              commit("newPlayer", newPlayer);
            }
          });
      } else {
        commit("changeError", i18n.t("error.somethingWrongTryAgain"));
      }
    },
  },
  mutations: {
    setIsNavOpen(state, yesno) {
      store.state.isNavOpen = window.innerWidth < 1025 ? yesno : true;
    },
    toggleNav() {
      store.state.isNavOpen =
        window.innerWidth < 1025 ? !store.state.isNavOpen : true;
    },
    changeError(state, payload) {
      store.state.error = payload;
    },
    clearError() {
      store.state.error = null;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setLoading(state, payload) {
      store.state.loading = payload;
    },
    setPasswordForgot(state, yesno) {
      store.state.passwordForgot = yesno;
    },
    changePlayerPage(state, page) {
      store.state.playerPage = page;
    },
    newPlayer(state, payload) {
      store.state.newPlayer = payload;
    },
    selectedPlayer(state, payload) {
      store.state.selectedPlayer = payload;
    },
    rateOrChangeMood(state, payload) {
      store.state.rateOrChangeMood = payload;
    },
    setNotification(state, payload) {
      store.state.notification = payload;
    },
    selectedIfSwitchi(state, yesno) {
      store.state.selectedIfSwitchi = yesno;
    },
    selectedIfPlaying(state, yesno) {
      store.state.selectedIfPlaying = yesno;
    },
    selectedSwitchOpt(state, options) {
      store.state.selectedSwitchOpt = options;
    },
    setMessages(state, payload) {
      store.state.user.totalMessages = payload;
    },
    switchCurrentRateMsg(state, payload) {
      store.state.currentRateOfMsg = payload;
    },
    switchSentLoader(state, payload) {
      store.state.sentMessageLoading = payload;
    },
    setUnreadMsgs(state, payload) {
      store.state.user.unreadMessages = payload;
    },
    changeHoldAddPlayer(state, payload) {
      store.state.holdAddPlayer = payload;
    },
    changeRegisteredPost(state, payload) {
      store.state.user.registered = payload;
    },
    changeApage(state, payload) {
      store.state.aPage = payload;
    },
    changeAllPlayers(state, payload) {
      store.state.allPlayers = payload;
    },
  },
  getters: {
    user() {
      return store.state.user.id;
    },
    navOpenOrNot: function () {
      return window.innerWidth < 1025 ? store.state.isNavOpen : true;
    },
    amountRegisteredPlayers() {
      return Object.keys(store.state.user.registeredPlayers).length;
    },
    email() {
      var user = firebase.auth().currentUser;
      if (user != null) {
        return user.email;
      } else {
        return "empty";
      }
    },
  },
});

function sortFunction(a, b) {
  if (a.name === b.name) {
    return 0;
  } else {
    return a.name < b.name ? -1 : 1;
  }
}
